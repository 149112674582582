import React, {useEffect, useMemo, useState} from 'react';
import {InformationCircleIcon, TrashIcon} from "@heroicons/react/24/outline";
import {useDispatch, useSelector} from "react-redux";
import {
    addNewPill,
    changeCalculationThirdLevelValue,
    removeCalculationItem, removePill,
} from "../../store/orderReducer";
import axios from "../../api/axios";
import * as PropTypes from 'prop-types';
import { MDBInput } from 'mdb-react-ui-kit';
import Select from 'react-select';
import TablePreloader from '../UI/TablePreloader';

const Step3 = () => {
    const [pillsList, setPillsList] = useState([]);
    const [pillsListForSelector, setPillsListForSelector] = useState([]);
    const [measurements, setMeasurements] = useState([]);
    const step = useSelector(state => state.steps.step)
    const dispatch = useDispatch();
    const calculations = useSelector(state => state.order.calculations);

    useMemo(() => {
        axios.get('/api/get-pills-list')
            .then(response => {
                setPillsList(response.data)
                const transformedData = response.data.map(item => {
                    return {
                        label: item.name,
                        value: item.id
                    };
                });
                setPillsListForSelector(transformedData);
            })
            .catch(function (error) {
                console.log(error)
            });
    }, []);

    useMemo(() => {
        axios.get('/api/get-measurements', {
            headers: {
                'Content-Type': 'application/json;'
            }
        })
          .then(response => {
              setMeasurements(response.data);
          })
          .catch(function (error) {
              console.log(error)
          });
    }, []);

    const totalCount = (calculation) => {
        let totalSpend = 0;
        if (calculation && calculation.calculation.pills) {
            calculation.calculation.pills.forEach((pill) => {
                totalSpend += pill.price * pill.quantity;
            });
        }
        return totalSpend.toFixed(2);
    };

    const changePills = (category, calculationIndex, pillsIndex, id, name) => {
        if(pillsList.length>0) {
            const selectedPill = pillsList.find(pill => pill.id === id);

            if (selectedPill) {
                dispatch(changeCalculationThirdLevelValue([category, calculationIndex, "pills", pillsIndex, "id", id]))
                dispatch(changeCalculationThirdLevelValue([category, calculationIndex, "pills", pillsIndex, "name", name]))
                dispatch(changeCalculationThirdLevelValue([category, calculationIndex, "pills", pillsIndex, "price", selectedPill.price]))
                dispatch(changeCalculationThirdLevelValue([category, calculationIndex, "pills", pillsIndex, "measurement_type", selectedPill.measurement_type]))
            }
        }
    }

    return (
        <div className={(step===3) ? "block" : "hidden"}>
            <div className="flex w-full mb-4 rounded-lg bg-orange-100 p-3 text-base" role="alert">
                <div className="flex items-center justify-center">
                    <InformationCircleIcon className="w-6 h-6 mr-4"/>
                </div>
                <div>
                    <p className="mb-0">Заповніть втирати матеріалів.</p>
                    <p className="mb-0">При необхідності - ви можете актуалізувати ціну в наступному розділі</p>
                </div>
            </div>
            {(calculations && pillsList.length > 0 && measurements.length > 0) ?
            Object.keys(calculations).map((category, categoryIndex)=>
                <div key={`category_${category}_${categoryIndex}`}>
                    <div className="bg-gray-200 rounded text-xl underline font-bold text-center mb-3 py-2">{category}</div>
                    {calculations[category].map(calculation =>
                        <div key={`calculation_${calculation.id}`} className="">
                            <div className="flex mb-4">
                                <div className="flex items-center w-1/3">
                                    {/*<div className="w-auto">*/}
                                    {/*    <TrashIcon  onClick={()=>dispatch(removeCalculationItem(calculation.id))} className="w-10 h-10 text-danger p-1 cursor-pointer hover:scale-125 transition duration-500 ease-in-out"/>*/}
                                    {/*</div>*/}
                                    <div className="text-xl font-bold">{calculation.name}</div>
                                </div>
                                <div className="flex justify-center items-center w-1/3">
                                    <div className="flex items-center bg-green-100 px-4 py-2 rounded-xl">
                                        <div className="text-xs">Розрахована вартість:</div>
                                        <div className="text-sm font-bold mx-1">{totalCount(calculation)}</div>
                                        <div className="text-xs">грн.</div>
                                    </div>
                                </div>
                            </div>
                            {calculation.calculation.pills.map((pills, pillsIndex) =>
                                <div key={`pills_${pillsIndex}`} className="flex items-center gap-x-4 mb-3">
                                    <div className="w-full">
                                        <Select
                                            options={pillsListForSelector}
                                            placeholder="Матеріал"
                                            value={{ label: pills.name, value: pills.id }}
                                            onChange={e=>changePills(category, calculation.id, pillsIndex, e.value, e.label)}
                                            isSearchable
                                        />
                                    </div>
                                    <div className="w-48">
                                        <MDBInput
                                          type="text"
                                          disabled={true}
                                          label="Од.виміру"
                                          value={pills.measurement_type}
                                        />
                                    </div>
                                    <div className="w-36">
                                        <div className="flex relative w-full mr-2">
                                            <MDBInput
                                                type="number"
                                                disabled={true}
                                                id={"pill"+pills.id+"Price"}
                                                label="Вартість"
                                                value={pills.price}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-36">
                                        <div className="flex relative w-full mr-2">
                                            <MDBInput
                                                type="number"
                                                id={"pill"+pills.id+"quantity"}
                                                label="Витрати"
                                                value={pills.spend}
                                                onChange={(e)=>dispatch(changeCalculationThirdLevelValue([category, calculation.id, "pills", pillsIndex, "spend", parseInt(e.target.value, 10)]))}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-24 text-center">
                                        <div className="text-sm">Ціна</div>
                                        <div className="font-bold">{pills.price && pills.quantity ? (pills.price*pills.quantity).toFixed(2) : 0}</div>
                                    </div>
                                    <div className="w-max">
                                        <TrashIcon  onClick={()=>dispatch(removePill([category, calculation.id, pillsIndex] ))} className="w-6 h-6 text-danger  cursor-pointer hover:scale-125 transition duration-500 ease-in-out"/>
                                    </div>
                                </div>
                            )}
                            <div className="flex mt-4 justify-end">
                                <button
                                    type="button"
                                    onClick={()=>dispatch(addNewPill([category, calculation.id]))}
                                    className="inline-block rounded bg-success px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-success-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-success-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-success-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                                    Додати витрату матеріалу
                                </button>
                            </div>
                            <hr className="h-px my-8 bg-gray-600 border-0 dark:bg-gray-700"/>
                        </div>
                    )}
                </div>
            )
                : <TablePreloader/>
            }
        </div>
    );
};

export default Step3;
