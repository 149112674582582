import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import axios from "../../api/axios";
import tailwindPresets from "../TailwindPresets/TailwindPresets";
import {InformationCircleIcon, TrashIcon} from "@heroicons/react/24/outline";
import { changeCalculationBonus, changeCalculationKey, changeCalculationProfit } from '../../store/orderReducer';
import TablePreloader from '../UI/TablePreloader';

const Step5 = () => {
    const [isLoading, setIsLoading] = useState(false);
    const companyInfo = useSelector(state => state.order.companyInfo);
    const calculations = useSelector(state => state.order.calculations);
    const step = useSelector(state => state.steps.step);
    const dispatch = useDispatch();

    const [currentCalculation, setCurrentCalculation] = useState([]);

    const calculate = () => {
        setIsLoading(true);
        axios.post('/api/calculations/calculate-online', {
            companyInfo: companyInfo,
            calculations: calculations
        })
            .then(response => {
                setCurrentCalculation(response.data)
                setIsLoading(false);
            })
            .catch(function (error) {
                    console.log(error)
                }
            );
    }

    useEffect(() => {
        if(step === 5) {
            calculate()
        }
    }, [step, calculations]);

    const spinner = (
        <div
            className="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status">
        <span
            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
        >Loading...</span>
        </div>
    );

    // console.log(currentCalculation);

    return (
        <div className={(step === 5) ? 'block' : 'hidden'}>
            <div className="flex w-full mb-4 rounded-lg bg-orange-100 p-3 text-base"
                 role="alert">
                <div className="flex items-center justify-center">
                    <InformationCircleIcon className="w-6 h-6 mr-4" />
                </div>
                <div>
                    <p className="mb-0">Підкоригуйте розрахунок</p>
                    <p className="mb-0">Натисніть кнопку "Розрахувати" щоб отримати поперелню вартівть кожної послуги</p>
                </div>
            </div>
            {currentCalculation.length === 0 && (
                <TablePreloader/>
            )}
            {currentCalculation.length > 0 && currentCalculation.map((category, categoryIndex) => (
                <div key={categoryIndex} className="mt-4">
                    <div
                        className="bg-gray-200 rounded text-xl underline font-bold text-center mb-3 py-2">{category.category}</div>
                    <div>
                        <div className="flex justify-center">
                            <table className="table-auto border border-collapse w-full">
                                <thead>
                                <tr className="border">
                                    <th className="border px-4 py-2 bg-blue-100 w-1/12">Код послуги</th>
                                    <th className="border px-4 py-2 bg-blue-100 w-6/12">Назва послуги</th>
                                    <th className="border px-4 py-2 bg-blue-100 w-1/12">Премія, %</th>
                                    <th className="border px-4 py-2 bg-blue-100 w-1/12">Витрати разом</th>
                                    <th className="border px-4 py-2 bg-blue-100 w-1/12">Рентабельність</th>
                                    <th className="border px-4 py-2 bg-blue-100 w-1/12">Вартість</th>
                                </tr>
                                </thead>
                                <tbody>
                                {category.calculations.map((calculation, calculationIndex) =>
                                    <tr key={`calculation_${calculation.name}_${calculationIndex}`} className="border">
                                        <td className="border px-4 py-2 bg-warning-100">{calculation.companyId}</td>
                                        <td className="border px-4 py-2 bg-warning-100 text-center">{calculation.name}</td>
                                        <td className="border px-4 py-2 text-center">
                                            <input
                                                type="number"
                                                className='h-10 text-center border rounded-lg'
                                                value={calculations[category.category][calculationIndex].calculation.bonus}
                                                onChange={(e)=>dispatch(changeCalculationKey([category.category, calculation.id, 'bonus', +e.target.value]))}
                                            />
                                        </td>
                                        <td className="border px-4 py-2 bg-warning-100 text-center">{isLoading ? spinner : calculation.summary}</td>
                                        <td className="border px-4 py-2 text-center">
                                            <input
                                                type="number"
                                                className='h-10 text-center border rounded-lg'
                                                value={calculations[category.category][calculationIndex].calculation.profit}
                                                onChange={(e) => dispatch(changeCalculationKey([category.category, calculation.id, 'profit', +e.target.value]))}
                                            />
                                        </td>
                                        <td className="border px-4 py-2 bg-warning-100 text-center">{isLoading ? spinner : calculation.summaryWithProfit}</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                        <hr className="h-px my-8 bg-gray-600 border-0 dark:bg-gray-700" />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Step5;
