import {createStore, combineReducers, applyMiddleware} from "redux";
// import { composeWithDevTools} from "@redux-devtools/extension";
// import thunk from "redux-thunk";
import {usersReducer} from "./usersReducer";
import {stepsReducer} from "./stepsReducer";
import {orderReducer} from "./orderReducer";
import { persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage'

const reducers = combineReducers({
    users: usersReducer,
    steps: stepsReducer,
    order: orderReducer,
})

const persistConfig = {
    key: 'root',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(
    persistedReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    // composeWithDevTools(applyMiddleware(thunk))
);
