import React, { useState } from "react";
import Users from "./Users";
import Calculations from "./Calculations";
import Templates from "./Templates";
import Pills from "./Pills";
import { MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from 'mdb-react-ui-kit';

const AdminPanel = () => {
    const [fillActive, setFillActive] = useState("tab1");

    const handleFillClick = (value) => {
        if (value === fillActive) {
            return;
        }
        setFillActive(value);
    };

    return (
        <div className="container flex-1 px-6 mx-auto max-w-7xl">
            <h1 className="text-xl font-bold mb-4">Панель адміністратора</h1>
            <div className="mb-3">
                <MDBTabs fill className='mb-3'>
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => handleFillClick('tab1')} active={fillActive === 'tab1'}>
                            Розрахунки
                        </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => handleFillClick('tab2')} active={fillActive === 'tab2'}>
                            Користувачі
                        </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => handleFillClick('tab3')} active={fillActive === 'tab3'}>
                            Шаблони
                        </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => handleFillClick('tab4')} active={fillActive === 'tab4'}>
                            Прайс-лист
                        </MDBTabsLink>
                    </MDBTabsItem>
                </MDBTabs>

                <MDBTabsContent>
                    <MDBTabsPane open={fillActive === "tab1"}><Calculations/></MDBTabsPane>
                    <MDBTabsPane open={fillActive === "tab2"}><Users/></MDBTabsPane>
                    <MDBTabsPane open={fillActive === "tab3"}><Templates/></MDBTabsPane>
                    <MDBTabsPane open={fillActive === "tab4"}><Pills/></MDBTabsPane>
                </MDBTabsContent>
            </div>
        </div>
    );
}

export default AdminPanel;