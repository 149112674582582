import axios from "../api/axios";
import React, {useEffect, useState} from 'react';
import { InformationCircleIcon, TrashIcon, DocumentDuplicateIcon, PrinterIcon } from '@heroicons/react/24/outline';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import TailwindPresets from './TailwindPresets/TailwindPresets';
import { exportPdf } from './exportPdf';
import {
    STATUS_CONFIRMED,
    STATUS_CREATED,
    STATUS_FORMED,
    STATUS_PAYED,
    STATUS_SAVED,
    STATUS_SENT
} from '../constants/OrderStatus';
import { MDBSpinner } from 'mdb-react-ui-kit';
import { toast } from 'react-toastify';
import ModalAreYouSureToDelete from './ModalAreYouSureToDelete';
import { useDispatch } from 'react-redux';

const OrdersList = () => {

    const [ordersList, setOrdersList] = useState([]);
    const [isActiveDanger, setIsActiveDanger] = useState(false);
    const [modalToDelete, setModalToDelete] = useState(false);
    const [documents, setDocuments] = useState([
        {
            name: 'title',
            title: 'Титульний лист',
            loading: false
        },
        {
            name: 'company info',
            title: 'Інформація про компанію',
            loading: false
        },
        // {
        //     name: 'company info',
        //     title: 'Титульний + норми витрат матеріалів'
        // },
        // {
        //     name: 'company info',
        //     title: 'Прайс викорисовуємих матеріалів'
        // },
        // {
        //     name: 'company info',
        //     title: 'Розрахунок по заробітній платі'
        // },
        // {
        //     name: 'company info',
        //     title: 'Акт хронометражу'
        // },
        // {
        //     name: 'company info',
        //     title: 'Калькуляція'
        // },
        // {
        //     name: 'company info',
        //     title: 'Довідка про витрати'
        // },
        // {
        //     name: 'company info',
        //     title: 'Довідка про премії та рентабельність'
        // },
    ]);

    const getOrdersList = () => {
        axios.get(`/api/orders`)
            .then(response => setOrdersList(response.data))
            .catch(function (error) {
                console.log(error)
            });
    }

    useEffect(() => {
        if (ordersList.length === 0) {
            getOrdersList();
        }
    }, []);

    const sendToApprove = identifier => {
        axios.post('/api/calculations/approve-from-user', { identifier })
            .then(response => {
                    if (response.status === 200) {
                        getOrdersList();
                    }
                }
            )
            .catch(function(error) {
                    console.log(error);
                }
            );
    }

    const changeLoadingDocument = (documentName, bool) => {
        const newDocuments = [...documents];
        const index = newDocuments.findIndex(document => document.name === documentName);
        newDocuments[index].loading = bool;
        setDocuments(newDocuments);
    }

    const exportHandler = (url, payload, pdfName) => {
        exportPdf(url, payload, pdfName, changeLoadingDocument);
    }

    const deleteOrderHandler = (identifier) => {
        axios.delete(`/api/orders/${identifier}`)
            .then(response => {
                if (response.status === 200) {
                    toast.success(response.data.message);
                    getOrdersList();
                }
            })
            .catch(function (error) {
                console.log(error)
            })
            .finally(
                setModalToDelete(false)
            );
    }

    const createDublicate = (identifier) => {
        axios.post('/api/orders/duplicate', { identifier })
            .then(response => {
                if (response.status === 200) {
                    toast.success(response.data.message);
                    getOrdersList();
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const goToCalculation = (identifier) => {
        dispatch({ type: 'CHANGE_STEP', payload: 1 });
        navigate(`/calculations/create/${identifier}`);
    }

    return (
        <>
            <div className="container flex-1 px-6 mx-auto max-w-7xl">
                <div className="flex justify-between items-center my-4">
                    <div>
                        <h1 className="text-xl font-bold">Перелік розрахованих послуг</h1>
                        <small className="text-gray-500">В даному розділі зберігаються всі розраховані вами
                            послуги</small>
                    </div>
                    <NavLink
                        to="/calculations/create_order"
                        className="inline-block rounded bg-success px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-success-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-success-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-success-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                        Додати
                    </NavLink>
                </div>

                {ordersList.map((calculationItem, calculationIndex) =>
                    <div key={calculationIndex}
                         className="flex border border-1 border-gray-500 rounded-xl p-6  mb-4 divide-x shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                        <div className="w-1/4 pr-3">
                            {(calculationItem.status === STATUS_CREATED)
                                ?
                                <button onClick={() => setIsActiveDanger(calculationItem.identifier)}
                                        className="text-lg text-left text-blue-600 font-bold mb-2">{calculationItem.name}</button>
                                :
                                // <Link to={'/calculations/create/' + calculationItem.identifier}
                                //       className="text-lg text-left text-blue-600 font-bold mb-2">{calculationItem.name}</Link>
                                <p
                                  className="text-lg text-left text-blue-600 font-bold mb-2"
                                  onClick={() => goToCalculation(calculationItem.identifier)}
                                >
                                    {calculationItem.name}
                                </p>
                            }
                            <div
                                className={(isActiveDanger === calculationItem.identifier) ? 'flex mb-4 rounded-lg bg-orange-100 px-3 py-2 text-base text-warning-800' : 'hidden'}
                                role="alert">
                                <div className="flex items-center justify-center">
                                    <InformationCircleIcon className="w-5 h-5 mr-3" />
                                </div>
                                <div className="text-sm">
                                    Ви зможете почати розрахунок як тільки представник компанії підтвердить оплату
                                </div>
                            </div>
                            <div className="flex mb-2">
                                {/*<div className="font-bold mr-2">Статус: </div>*/}
                                <div>
                                    <p className="mt-2 text-sm font-bold">Статус:</p>
                                    <ol>
                                        <li className={calculationItem.status === STATUS_CREATED ? 'font-bold underline text-sm text-blue-800' : 'text-sm text-gray-400'}>-
                                            Сформована заявка на оплату
                                        </li>
                                        <li className={calculationItem.status === STATUS_PAYED ? 'font-bold underline text-sm text-blue-800' : 'text-sm text-gray-400'}>-
                                            Оплачено. Можна починати заповнення
                                        </li>
                                        <li className={calculationItem.status === STATUS_SAVED ? 'font-bold underline text-sm text-blue-800' : 'text-sm text-gray-400'}>-
                                            В процесі формування (чорновик)
                                        </li>
                                        <li className={calculationItem.status === STATUS_FORMED ? 'font-bold underline text-sm text-blue-800' : 'text-sm text-gray-400'}>-
                                            Розраховано. Готово до відправки на узгодження
                                        </li>
                                        <li className={calculationItem.status === STATUS_SENT ? 'font-bold underline text-sm text-blue-800' : 'text-sm text-gray-400'}>-
                                            Відправлено на узгодження
                                        </li>
                                        <li className={calculationItem.status === STATUS_CONFIRMED ? 'font-bold underline text-sm text-blue-800' : 'text-sm text-gray-400'}>-
                                            Розрахованою і узгоджено
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="w-2/4 px-3">
                            <div className="font-bold mb-2">Розрахунки/документи:</div>
                            {calculationItem.status === STATUS_CREATED &&
                                <div className="flex mb-4 rounded-lg bg-info-100 px-3 py-2 text-base" role="alert">
                                    <div className="flex items-center justify-center">
                                        <InformationCircleIcon className="w-5 h-5 mr-3" />
                                    </div>
                                    <div className="text-sm">
                                        Ви зможете заповнювати шаблони після оплати
                                    </div>
                                </div>
                            }
                            {(calculationItem.status === STATUS_PAYED || calculationItem.status === STATUS_SAVED) &&
                                <div className="flex mb-4 rounded-lg bg-info-100 px-3 py-2 text-base" role="alert">
                                    <div className="flex items-center justify-center">
                                        <InformationCircleIcon className="w-5 h-5 mr-3" />
                                    </div>
                                    <div className="text-sm">
                                        Завершіть розрахунок для вивантаження документів
                                    </div>
                                </div>
                            }
                            {(calculationItem.status === STATUS_FORMED || calculationItem.status === STATUS_SENT || calculationItem.status === STATUS_CONFIRMED) &&
                                <div>
                                    {documents.map((document, documentIndex) => (
                                        <div key={documentIndex} className="flex mb-1">
                                            <div className="w-8 h-6 mx-1 flex justify-center items-center">
                                                {document.loading ? (
                                                    <MDBSpinner color="danger" size="sm">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </MDBSpinner>
                                                ) : (
                                                    <img className="max-h-6" src="/images/pdf_icon.svg"
                                                         alt="PDF Icon" />
                                                )}
                                            </div>
                                            <button
                                                className="text-sm text-left leading-none text-blue-600"
                                                onClick={() => exportHandler('/api/calculations/pdf', {
                                                    file: document.name,
                                                    identifier: calculationItem.identifier
                                                }, document.name)}
                                            >
                                                {document.title} {(calculationItem.status === STATUS_FORMED || calculationItem.status === STATUS_SENT) && '(не узгоджений)'}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            }
                            {calculationItem.status === STATUS_FORMED &&
                                <>
                                    <button
                                        onClick={() => {
                                            sendToApprove(calculationItem.identifier);
                                        }}
                                        className={TailwindPresets.buttonSuccess + ' w-full mb-2 mt-2'}
                                    >
                                        Відправити на узгодження
                                    </button>
                                </>
                            }
                            {calculationItem.status === STATUS_SENT &&
                                <>
                                    <div className="flex mb-4 rounded-lg bg-blue-100 px-3 py-2 text-base mt-3"
                                         role="alert">
                                        <div className="flex items-center justify-center">
                                            <InformationCircleIcon className="w-5 h-5 mr-3" />
                                        </div>
                                        <div className="text-sm">
                                            Розрахунок в процесі узгодження
                                        </div>
                                    </div>
                                </>
                            }
                            {calculationItem.status === STATUS_CONFIRMED &&
                                <>
                                    <div className="flex mb-4 rounded-lg bg-success-600 px-3 py-2 mt-3 text-white"
                                         role="alert">
                                        <div className="flex items-center justify-center">
                                            <InformationCircleIcon className="w-5 h-5 mr-3" />
                                        </div>
                                        <div className="text-sm">
                                            Узгоджено
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className="w-1/4 px-3">
                            <div className="font-bold mb-2">Операції:</div>
                            <button
                                className="flex items-center text-sm text-blue-600 text-left leading-none mb-2"
                                onClick={() => createDublicate(calculationItem.identifier)}
                            >
                                <DocumentDuplicateIcon
                                    className="w-6 h-6 cursor-pointer hover:scale-125 transition duration-500 ease-in-out mr-1"
                                />
                                Створити новий розрахунок на основі даного
                            </button>
                            <button
                                className="flex items-center text-sm text-blue-400 text-left leading-none mb-2"
                            >
                                <DocumentDuplicateIcon
                                    className="w-6 h-6 cursor-pointer hover:scale-125 transition duration-500 ease-in-out mr-1"
                                />
                                Оновити дані в розрахунку (в розробці)
                            </button>
                            <button
                                className="flex items-center text-sm text-blue-400 text-left leading-none mb-2"
                                disabled={true}
                            >
                                <PrinterIcon
                                    className="w-6 h-6 cursor-pointer hover:scale-125 transition duration-500 ease-in-out mr-1"
                                />
                                Роздрукувати (в розробці)
                            </button>
                            <button
                                className="flex items-center text-sm text-left leading-none text-red-600"
                                onClick={() => setModalToDelete(true)}
                            >
                                <TrashIcon
                                    className="w-5 h-5 cursor-pointer hover:scale-125 transition duration-500 ease-in-out mr-1" />
                                Видалити
                            </button>
                            <ModalAreYouSureToDelete
                                modalToDelete={modalToDelete}
                                setModalToDelete={setModalToDelete}
                                deleteOrder={() => deleteOrderHandler(calculationItem.identifier)}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default OrdersList;
