import React from "react";

const Footer = () => {
    return (
      <div className="container mx-auto max-w-7xl px-6">
          <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"/>
          <div className="flex justify-around gap-4">
              <div className="">
                  <h5 className="font-bold mb-2">Компанія</h5>
                  <div><a className="text-gray-600">Про команію</a></div>
                  <div><a className="text-gray-600">Контакти</a></div>
                  <div><a className="text-gray-600">Свідоцтво про реєстрацію</a></div>
              </div>
              <div className="">
                  <h5 className="font-bold mb-2">Гарантії</h5>
                  <div><a className="text-gray-600">Договір про нерозголошення персональних даних</a></div>
                  <div><a className="text-gray-600">Угода користувача</a></div>
                  <div><a className="text-gray-600">Договір оферти</a></div>
              </div>
              <div className="">
                  <h5 className="font-bold mb-2">Підтримка</h5>
                  <div><a className="text-gray-600">Гаряча лінія</a></div>
                  <div><a className="text-gray-600">Чат-підтримка</a></div>
                  <div><a className="text-gray-600">Поставити запитання</a></div>
              </div>
          </div>
          <div className="flex justify-center my-4">
              <small className="text-gray-500">
                  © копірайт. Всі права захищені. Надіюсь 2023
              </small>
          </div>
      </div>

    )
}

export default Footer
