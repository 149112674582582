import React, {useEffect, useState} from 'react';
import {ExclamationCircleIcon} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";
import OrderList from "./OrderList";
import axios from "../../api/axios";
import {useSelector} from "react-redux";
import { MDBAccordion, MDBAccordionItem, MDBInput } from 'mdb-react-ui-kit';
import TablePreloader from '../UI/TablePreloader';

const CreateOrder = () => {
    const userId = useSelector(state => state.users.currentUser.id);
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}.${currentDate.getMonth() + 1}.${currentDate.getFullYear()}`;
    const [newOrder, setNewOrder] = useState({
        name: "Розрахунок за: " + formattedDate,
        companyInfo: {
            "name": '',
            "code": '',
            "payment_code": '',
            "form": '',
            "ceo_name": '',
            "ceo_phone": '',
            "counter_name": '',
            "counter_phone": '',
            "address": '',
            "medical_staff_salary_fund": '',
            "supply_staff_salary_fund": '',
            "food_service_staff_salary_fund": '',
            "inpatient_stay_costs": '',
            "additional_services_inpatient_stay_costs": '',
            "food_expenses": '',
            "additional_services_food_patients": '',
            "other_direct_expenses": '',
            "general_staff_salary_fund": '',
            "operational_expenses": '',
            "general_depreciation": '',
            "other_general_expenses": '',
            "administrative_staff_salary_fund": '',
            "other_administrative_expenses": '',
            "advertising_material_creation_and_placement_budget": '',
            "rented_by_external_organizations_area": '',
            "medical_service_rooms_area": '',
            "patient_bedwards_area": '',
            "kitchen_and_canteen_area": '',
            "other_areas": '',
            "unified_social_security_contribution_rate": '',
            "actual_amount_for_leave_payment": '',
            "total_number_of_beds": ''
        },
        calculations: []
    })

    const [templates, setTemplates] = useState([]);
    const [ownCalculations, setOwnCalculations] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const fetchTemplates = () => {
        axios.get('/api/get-templates-list')
            .then(response => setTemplates(response.data))
            .then(response => setIsDataLoaded(true))
            .catch(function (error) {
                console.log(error)
            });
    }

    const fetchOwnCalculations = () => {
        axios.get('/api/get-own-calculations')
            .then(response => setOwnCalculations(response.data))
            .then(response => setIsDataLoaded(true))
            .catch(function (error) {
                console.log(error)
            });
    }

    const fetchCompanyInfo = () => {
        axios.get(`/api/company-info/${userId}`)
            .then(response => setNewOrder(prevOrder => ({
                ...prevOrder,
                companyInfo: response.data
            })))
            .catch(function (error) {
                console.log(error)
            });
    }

    useEffect(() => {
        if (templates.length === 0) {
            fetchTemplates();
            fetchOwnCalculations();
            fetchCompanyInfo();
        }
    }, []);

    const changeOrderName = (name) => {
        setNewOrder(prevOrder => ({
            ...prevOrder,
            name: name
        }));
    }

    const changeOrderList = (e, categoryId, templateId, templateName, isOwnOrder) => {
        if (e.target.checked) {
            setNewOrder(prevOrder => ({
                ...prevOrder,
                calculations: [...prevOrder.calculations, { categoryId, templateId, templateName, isOwnOrder }]
            }));
        } else if (!e.target.checked) {
            setNewOrder(prevOrder => ({
                ...prevOrder,
                calculations: prevOrder.calculations.filter(item => item.templateId !== templateId)
            }));
        }
    };

    console.log(newOrder);

    const addNewCalculation = () => {
        const newCalculationObj = {
            "categoryId": null,
            "templateId": null,
        };

        setNewOrder(prevOrder => ({
            ...prevOrder,
            calculations: [...prevOrder.calculations, newCalculationObj]
        }));
    }

    const changeOrderValue = (index, name, value) => {
        let newOrderCopy = {...newOrder};
        newOrderCopy.calculations[index][name] = value;
        setNewOrder(newOrderCopy);
    }

    return (
        <>
            <div className="container flex-1 px-6 mx-auto max-w-7xl">
                <nav className="w-full rounded-md mb-3">
                    <ol className="list-reset flex">
                        <li>
                            <Link
                                to="/calculations"
                                className="text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                            >Розрахунок послуг
                            </Link>
                        </li>
                        <li>
                            <span className="mx-2 text-neutral-500 dark:text-neutral-400">/</span>
                        </li>
                        <li className="text-neutral-500 dark:text-neutral-400">Створити нове замовлення на розрахунок
                        </li>
                    </ol>
                </nav>
                <div className="flex">
                    <div className="flex w-full mb-4 rounded-lg bg-orange-100 p-3 text-base" role="alert">
                        <div className="flex items-center justify-center">
                            <ExclamationCircleIcon className="w-6 h-6 mr-3" />
                        </div>
                        <div>
                            Виберіть шаблони по яким плануєте зробити розрахунки або створіть нові розрахунки
                        </div>
                    </div>
                </div>
                <div className="relative mb-3">
                    <MDBInput
                        type="text"
                        id="orderName"
                        label="Назва розрахунку"
                        value={newOrder.name}
                        onChange={e => changeOrderName(e.target.value)}
                    />
                </div>
                <OrderList
                    calculations={newOrder.calculations}
                    addNewCalculation={addNewCalculation}
                    changeOrderValue={changeOrderValue}
                    newOrder={newOrder}
                />
                <div className="font-bold mt-8 mb-4">Шаблони власних розрахунків:</div>
                <MDBAccordion>
                    <MDBAccordionItem collapseId={1} headerTitle={"Власні розрахунки компанії"}
                                      className="bg-blue-500">
                        {ownCalculations.map(ownCalculation =>
                            <div key={ownCalculation.calculationId}
                                 className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                                <input
                                    className="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] rounded-[0.25rem] border-[0.125rem] border-solid border-neutral-300 outline-none"
                                    type="checkbox"
                                    value=""
                                    onClick={(e) => changeOrderList(e, null, ownCalculation.calculationId, ownCalculation.name, true)}
                                    id={"checkbox_own" + ownCalculation.calculationId}
                                />
                                <label
                                    className="inline-block pl-[0.15rem] hover:cursor-pointer"
                                    htmlFor={"checkbox_own" + ownCalculation.calculationId}>
                                    {ownCalculation.name}
                                </label>
                            </div>
                        )}
                    </MDBAccordionItem>
                </MDBAccordion>
                <div className="font-bold mt-8 mb-4">Перелік доступних шаблонів розрахунків по категоріям:</div>
                {isDataLoaded ? (
                    <MDBAccordion>
                        {templates.map((category, index) =>
                            <MDBAccordionItem key={index} collapseId={index + 2} headerTitle={category.categoryName}>
                                {category.templates.map(template =>
                                    <div key={template.templateId}
                                         className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                                        <input
                                            className="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] rounded-[0.25rem] border-[0.125rem] border-solid border-neutral-300 outline-none"
                                            type="checkbox"
                                            value=""
                                            onClick={(e) => changeOrderList(e, category.categoryId, template.templateId, template.templateName, false)}
                                            id={"checkbox" + template.templateId}
                                        />
                                        <label
                                            className="inline-block pl-[0.15rem] hover:cursor-pointer"
                                            htmlFor={"checkbox" + template.templateId}>
                                            {template.templateName}
                                        </label>
                                    </div>
                                )}
                            </MDBAccordionItem>
                        )}
                    </MDBAccordion>
                ) : <TablePreloader />}
            </div>
        </>
    );
};

export default CreateOrder;
