import React, {useEffect, useRef, useState, useContext } from 'react';
import {Link, useParams, useSearchParams} from "react-router-dom";
import axios from "../api/axios";

const ResetPassword = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [status, setStatus] = useState(null)
    const [errors, setErrors] = useState([]);
    const { token } = useParams();

    const [searchParams] = useSearchParams()

    useEffect(() => {
        setEmail(searchParams.get('email'));
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.get('http://localhost:8000/sanctum/csrf-cookie');
        setErrors([]);
        setStatus(null)
        try{
            const response = await axios.post('/password/reset', {
                token: token,
                email: email,
                password: password,
                password_confirmation: passwordConfirm
            });
            console.log(response.data.message);
            setStatus(response.data.message);
        } catch (err) {
            if (err.status === 422){
                setErrors(err.response.data.errors)
                console.log('err.response.data.errors.password')
            }
        }
    };

    return (
        <section className="container px-6 mx-auto max-w-7xl h-screen">
            <div className="h-full">
                <div className="mb-12 md:mb-0 md:w-8/12 lg:w-5/12 xl:w-5/12 mx-auto">
                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-row items-center justify-center lg:justify-start mb-4">
                            <p className="mb-0 mr-4 text-lg">Set your new password</p>
                        </div>
                        {/* Password input */}
                        <div className="relative mt-6" data-te-input-wrapper-init>
                            <input
                                type="password"
                                className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                id="userPwd"
                                placeholder="Password"
                                onChange={(e)=>setPassword(e.target.value)}
                                value={password}
                                required
                            />
                            <label
                                htmlFor="userPwd"
                                className="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[2.15] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[1.15rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[1.15rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
                            >Password
                            </label>
                        </div>
                        {errors.password && (<p className="text-sm text-red-500">{errors.password[0]}</p>)}
                        {/* Confirm password input */}
                        <div className="relative mt-6" data-te-input-wrapper-init>
                            <input
                                type="password"
                                className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                id="userPwdConfirm"
                                placeholder="Password"
                                onChange={(e)=>setPasswordConfirm(e.target.value)}
                                value={passwordConfirm}
                                required
                            />
                            <label
                                htmlFor="userPwdConfirm"
                                className="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[2.15] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[1.15rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[1.15rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
                            >Confirm password
                            </label>
                        </div>
                        {status && <div className="bg-green-700 my-2 p-2 rounded text-white">{status}. Go to <Link to='/'><span className="font-bold"> main gage</span></Link></div>}
                        {/* Login button */}
                        <div className="text-center lg:text-left flex justify-center mt-4">
                            <button
                                className="inline-block rounded bg-primary px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                                data-te-ripple-init
                                data-te-ripple-color="light">
                                Reset password
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default ResetPassword;
