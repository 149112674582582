import React, {useEffect, useState} from 'react';
import axios from "../../api/axios";
import {PencilSquareIcon, TrashIcon} from "@heroicons/react/24/outline";
import { toast } from 'react-toastify';
import {
    STATUS_CONFIRMED,
    STATUS_CREATED,
    STATUS_FORMED,
    STATUS_PAYED,
    STATUS_SAVED,
    STATUS_SENT
} from '../../constants/OrderStatus';
import TailwindPresets from '../TailwindPresets/TailwindPresets';
import { MDBBtn, MDBBtnGroup } from 'mdb-react-ui-kit';
import clsx from 'clsx';

const Calculations = () => {
    const [calculations, setCalculations] = useState([])

    const getCalculation = () => {
        axios.get(`/api/administrator/orders`)
            .then(response => setCalculations(response.data))
            .catch(function (error) {
                console.log(error)
            });
    }

    useEffect(() => {
        getCalculation();
    }, []);

    const changeStatus = (identifier, status) => {
        axios.patch(`/api/administrator/orders/change-status`, {identifier, status})
            .then(response => {
                if (response.status === 200) {
                    getCalculation();
                    toast.success(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error)
                toast.error(error.response.data.message);
            });
    }

    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day}.${month}.${year} ${hours}:${minutes}`;
    }

    return (
        <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 sm:px-6">
                    <div className="overflow-hidden">
                        <table className="min-w-full text-left text-sm font-light">
                            <thead className="border-b font-medium dark:border-neutral-500">
                            <tr>
                                <th scope="col" className="px-6 py-4">ID</th>
                                <th scope="col" className="px-6 py-4">Назва</th>
                                <th scope="col" className="px-6 py-4">Компанія</th>
                                <th scope="col" className="px-6 py-4"></th>
                                <th scope="col" className="px-6 py-4">Статус</th>
                                <th scope="col" className="px-6 py-4">Створено</th>
                                <th scope="col" className="px-6 py-4">Оновлено</th>
                                <th scope="col" className="px-6 py-4">Дії</th>
                            </tr>
                            </thead>
                            <tbody>
                            {calculations.map((calculation) =>
                                <tr
                                    key={calculation.identifier}
                                    className="border-b transition duration-300 ease-in-out hover:bg-gray-100 dark:border-neutral-500">
                                    <td className="whitespace-nowrap px-6 py-4 font-medium">{calculation.identifier}</td>
                                    <td className="whitespace-nowrap px-6 py-4">{calculation.name}</td>
                                    <td className="whitespace-nowrap px-6 py-4">{calculation.company}</td>
                                    <td className="whitespace-nowrap px-6 py-4">
                                        <button
                                            // onClick={}
                                            className={TailwindPresets.buttonSuccess}
                                        >
                                            Відкрити
                                        </button>
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-4">
                                        <div className="inline-flex" role="group">
                                            <MDBBtnGroup aria-label='Basic example'>
                                                <MDBBtn
                                                    color={calculation.status === STATUS_CREATED ? 'primary' : 'info'}
                                                    className="px-2"
                                                    onClick={() => changeStatus(calculation.identifier, STATUS_CREATED)}
                                                >
                                                    Створено
                                                </MDBBtn>
                                                <MDBBtn
                                                    color={calculation.status === STATUS_PAYED ? 'primary' : 'info'}
                                                    className="px-2"
                                                    onClick={() => changeStatus(calculation.identifier, STATUS_PAYED)}
                                                >
                                                    Оплачено
                                                </MDBBtn>
                                                <MDBBtn
                                                    color={calculation.status === STATUS_SAVED ? 'primary' : 'info'}
                                                    className="px-2"
                                                    onClick={() => changeStatus(calculation.identifier, STATUS_SAVED)}
                                                >
                                                    Збережено
                                                </MDBBtn>
                                                <MDBBtn
                                                    color={calculation.status === STATUS_FORMED ? 'primary' : 'info'}
                                                    className="px-2"
                                                    onClick={() => changeStatus(calculation.identifier, STATUS_FORMED)}
                                                >
                                                    Сформовано
                                                </MDBBtn>
                                                <MDBBtn
                                                    color={calculation.status === STATUS_SENT ? 'primary' : 'info'}
                                                    className="px-2"
                                                    onClick={() => changeStatus(calculation.identifier, STATUS_SENT)}
                                                >
                                                    Відправлено
                                                </MDBBtn>
                                                <MDBBtn
                                                    color={calculation.status === STATUS_CONFIRMED ? 'primary' : 'info'}
                                                    className="px-2"
                                                    onClick={() => changeStatus(calculation.identifier, STATUS_CONFIRMED)}
                                                >
                                                    Узгоджено
                                                </MDBBtn>
                                            </MDBBtnGroup>
                                        </div>
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-4">{formatDate(calculation.createdAt)}</td>
                                    <td className="whitespace-nowrap px-6 py-4">{formatDate(calculation.updatedAt)}</td>
                                    <td className="whitespace-nowrap px-6 py-4 flex">
                                        <TrashIcon
                                            className="w-6 h-6 text-danger cursor-pointer hover:scale-125 transition duration-500 ease-in-out" />
                                        <PencilSquareIcon
                                            className="ml-2 w-6 h-6 text-success cursor-pointer hover:scale-125 transition duration-500 ease-in-out" />
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Calculations;