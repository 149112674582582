import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import { JSONTree } from 'react-json-tree';
import {NavLink} from "react-router-dom";

const CalculationResult = () => {
    const calculations = useSelector(state => state.calculations.calculations)

    const theme = {
        scheme: 'twilight',
        author: 'david hart (http://hart-dev.com)',
        base00: '#1e1e1e',
        base01: '#323537',
        base02: '#464b50',
        base03: '#5f5a60',
        base04: '#838184',
        base05: '#a7a7a7',
        base06: '#c3c3c3',
        base07: '#ffffff',
        base08: '#cf6a4c',
        base09: '#cda869',
        base0A: '#f9ee98',
        base0B: '#8f9d6a',
        base0C: '#afc4db',
        base0D: '#7587a6',
        base0E: '#9b859d',
        base0F: '#9b703f'
    };

    return (
        <div className="container flex-1 px-6 mx-auto max-w-7xl">
            <div className="flex justify-start">
                <NavLink to="/calculations/create"
                     class="bg-success rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#14a44d] transition duration-150 ease-in-out hover:bg-success-600 hover:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] focus:bg-success-600 focus:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] focus:outline-none focus:ring-0 active:bg-success-700 active:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(20,164,77,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)]">
                    Назад
                </NavLink>
            </div>
            {calculations.map((item, index)=>
                <div key={index}>
                    <JSONTree theme={theme} data={item} />
                </div>
            )}

        </div>
    );
};

export default CalculationResult;
