import './App.css';
import Header from "./components/Header";
import {Route, Routes} from "react-router-dom";
import Home from "./components/Home";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import Error from "./components/Error";
import AuthLayout from "./layout/AuthLayout";
import CreateOrder from "./components/CreateOrder/CreateOrder";
import OrdersList from "./components/OrdersList";
import ChangeOrder from "./components/ChangeOrder/ChangeOrder";
import CalculationResult from "./components/CalculationResult";
import CompanyInfo from "./components/CompanyInfo";
import Medicines from "./components/Medicines";
import Staff from "./components/Staff";
import GuestLayout from "./layout/GuestLayout";
import Login from "./components/Login";
import Registration from "./components/Registration";
import Footer from "./components/Footer";
import AdminPanel from "./components/AdminPanel/AdminPanel";
import React from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  return (
    <div className="App">
        <div className="flex flex-col min-h-screen">
            <Header/>
                <Routes>
                    <Route exact path="/" element={<Home/>}/>
                    <Route exact path="/forgot-password" element={<ForgotPassword/>}/>
                    <Route exact path="/password/reset/:token" element={<ResetPassword/>}/>
                    <Route path="*" element={<Error/>}/>

                    <Route element={<AuthLayout/>}>
                        <Route exact path="/calculations/create_order" element={<CreateOrder/>}/>
                        <Route exact path="/calculations" element={<OrdersList/>}/>
                        <Route exact path="/calculations/create/:identifier" element={<ChangeOrder/>}/>
                        <Route exact path="/calculation-result" element={<CalculationResult/>}/>
                        <Route exact path="/company" element={<CompanyInfo/>}/>
                        <Route exact path="/medicines" element={<Medicines/>}/>
                        <Route exact path="/staff" element={<Staff/>}/>
                        <Route exact path="/admin-panel" element={<AdminPanel/>}/>
                    </Route>

                    <Route exact path="/login" element={<Login/>}/>

                    <Route element={<GuestLayout/>}>
                        <Route exact path="/Registration" element={<Registration/>}/>
                    </Route>

                </Routes>
            <Footer/>
        </div>
        <ToastContainer />
    </div>
  );
}

export default App;
